import React from "react"
import Container from "../../components/Container"

import Balance from "../../images/balance.png"

import "./index.scss"

const About = () => {
  return (
    <div className="section about">
      <Container>
        <div className="about__header">
          <div className="about__header extrabold">РЕФІНАНСУВАННЯ </div>
          <div className="about__header regular">В ТАСКОМБАНК це:</div>
        </div>
        <div className="about__wrapper">
          <div className="about__items">
            <div className="about__item about__item--line">
              <div className="about__item--light light">Сума кредиту</div>
              <div className="about__item--bold extrabold">
                до 200 тисяч грн
              </div>
            </div>
            <div className="about__item about__item--bg">
              <div className="about__item--light light">Строк кредиту</div>
              <div className="about__item--bold extrabold">до 5 років</div>
            </div>
            <div className="about__item about__item--line">
              <div className="about__item--light light">
                Вигідна щомісячна комісія
              </div>
              <div className="about__item--bold extrabold">від 2,5%</div>
            </div>
            <div className="about__item about__item--bg">
              <div className="about__item--light light">
                Відсутність разової комісії
              </div>
              <div className="about__item--bold extrabold">
                та страхування життя
              </div>
            </div>
          </div>
          <div className="about__image">
            <img src={Balance} alt={"Рефінансування"} />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default About
